html,
body {
  margin: 0px;
  padding: 0px;
  height: 100%;
}

body {
  background: #f6f8fa;
  font-family: CiutadellaRounded-Rg;
  text-shadow: none;
  font-size: 14px;
  line-height: 1.5;
  font-family: Lato;
  height: 100%;
}

#singularStatus {
  height: 100%;
}

.page-wrapper {
  height: 100%;
}

.page-header {
  box-shadow: 0 1px 15px rgba(27, 31, 35, 0.15);
  height: 70px;
  display: flex;
  width: 100%;
  align-items: center;
  background: #fff;
  position: relative;
  justify-content: space-between;

  .logo {
    height: 20px;
  }

  a {
    background-color: transparent;
    color: #0366d6;
    text-decoration: none;
    font-size: 16px;
    margin: 0px 20px;
  }
}

.status-wrapper {
  background: #f6f8fa;
  height: calc(100% - 70px);

  .status-table {
    display: flex;
    height: calc(100% - 80px);
    flex-direction: column;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;

    .status-header {
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      text-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
      margin-bottom: 40px;
      padding: 0.75rem 1.25rem;
      width: 90%;
      max-width: 700px;
      font-weight: 500;
      font-size: 1.25rem;
      line-height: 1.8125rem;
      color: #fff;
      height: 30px;

      .fa {
        margin: 0px 10px;
      }
      &.success {
        background: #28a745;
      }
      &.pending {
        background: #0366d6;
      }
      &.error {
        background: #e74c3c;
      }
    }

    .status-body {
      width: 90%;
      max-width: 700px;
      height: calc(100% - 30px);
      overflow-y: scroll;

      .status-row {
        display: flex;
        justify-content: space-between;
        padding: 1.1rem 1.25rem 1rem;
        border: solid 1px rgba(0, 0, 0, 0.125);
        font-size: 1rem;
        line-height: 1.5rem;
        border-top-width: 0px;
        background: #fff;

        &:first-child {
          border-top-width: 1px;
        }

        &.header {
          background: transparent;
        }

        .r-service-name {
          width: 35%;
        }

        .r-service-status {
          text-align: center;
          width: 60px;
        }

        .r-service-message {
          width: 50%;
        }

        .r-service-toggle {
          width: 50%;
          text-align: right;

          button {
            cursor: pointer;
            width: 30px;
            height: 20px;
            color: #999c9e;
            background: #fff;
            border: 1px solid #bbb;
            font-size: 11px;
            border-radius: 0.25rem;
            outline: 0px;
          }
          button:hover {
            background: #f5f5f5;
            border-color: #bbb;
            color: #555;
          }
        }

        .r-service-last {
          text-align: right;
          width: 170px;
        }

        .fa {
          &.success {
            color: #28a745;
          }
          &.error {
            color: #e74c3c;
          }
          &.warning {
            color: #ffaa00;
          }
          &.pending {
            color: #0366d6;
          }
        }
      }
    }

    .offset {
      height: 10px;
      border-bottom: solid 1px rgba(0, 0, 0, 0.125);
    }
  }
}

.login-wrapper {
  height: 100%;
  background-color: #f6f8fa;

  a {
    color: #38a6e1;
  }
  .sub-text {
    margin: 20px 0px;
  }

  .section-body {
    height: calc(100% - 70px);
    display: flex;
    justify-content: center;
    align-items: center;
    text-shadow: none;
    background-color: #f4f4f5;
    color: #6d6e71;
    font-size: 14px;
  }

  .section-title {
    font-size: 36px;
    margin-bottom: 35px;
    text-align: center;
    color: #38a6e1;
    font-family: "Lato";
    position: relative;
    padding-bottom: 10px;
  }

  .section-title:after {
    content: " ";
    height: 1px;
    width: 100%;
    background-color: #38a6e1;
    position: absolute;
    left: 0px;
    bottom: -5px;
  }

  .login-inner {
    padding: 30px 40px 40px 40px;
    box-shadow: #00000040 4px 4px 5px;
    background-color: #fff;
    min-width: 450px;
    position: relative;
    width: 40%;
    margin: auto;
    top: 150px;
    font-family: "Lato";

    form {
      max-width: 300px;
      margin: auto;
    }

    .icon {
      text-align: center;
      color: #dcdcdc;
      font-size: 100px;
      width: 100%;
    }

    .text {
      font-size: 18px;
      margin: 15px;
      text-align: center;
    }
  }
}